import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import PublicationLayout from "../components/publicationLayout"
import SEO from "../components/seo"
import { Row, Col, Container, Carousel } from "react-bootstrap"


class PublicationPage extends React.Component {
  render() {
    return(
      <React.Fragment>
      <PublicationLayout pageInfo={{ pageName: this.props.data.contentfulPublication.publicationTitle }}>
        <SEO title={this.props.data.contentfulPublication.publicationTitle} keywords={[this.props.data.contentfulPublication.publicationTitle]} />
        <Container className="publication-page">
          <Row noGutters className="unhistoried-publication-row">
            <Col className="unhistoried-publication-col">
              <div>
                <h1 className="unhistoried-publication-title">{this.props.data.contentfulPublication.publicationTitle}</h1>
              </div>
              <div dangerouslySetInnerHTML={{ __html: this.props.data.contentfulPublication.publicationDescription.childMarkdownRemark.html.replace(/href/g, "target='_blank' href") }}></div>
            </Col>
          </Row>
        </Container>
      </PublicationLayout>
      <Carousel fade indicators={false} nextIcon={<i class="bi bi-arrow-right carousel-icon"></i>} prevIcon={<i class="bi bi-arrow-left carousel-icon"></i>}>
        {this.props.data.contentfulPublication.publicationImages.map((item, index) => {
          return (
            <Carousel.Item interval={5000}>
              <GatsbyImage className="landing-image" image={item.gatsbyImageData} />
            </Carousel.Item>
          )
        })}
      </Carousel>
      </React.Fragment>
    )
  }
}


export const PublicationPageQuery = graphql`
query PublicationDataQuery($publicationTitle: String!) {
  contentfulPublication(publicationTitle: {eq: $publicationTitle}) {
    id
    publicationDescription {
      childMarkdownRemark {
        html
      }
    }
    publicationImages {
      gatsbyImageData(layout: FULL_WIDTH)
    }
    publicationTitle
  }
}`

export default PublicationPage
